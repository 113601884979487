import React, { useState } from "react";
import { FaWhatsapp, FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import emailjs from 'emailjs-com';

const ContactUs = (props) => {
    const mdStyle = "md:p-12"
    const style = "relative h-auto flex flex-col items-center justify-center p-4 text-gray-800 py-16"
    const {comp, className} = { ...{comp:false, className:`${mdStyle} ${style}`}, ...props};

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        emailjs.send('service_c8dohus', 'template_1l0kgzj', {
            name,
            email,
            message
        }, '5fFV35QHCJFzKbEm5')
        .then(response => {
            console.log('SUCCESS:', response);
            // Formu sıfırla
            setName('');
            setEmail('');
            setMessage('');
        })
        .catch(err => console.error('FAILED:', err));
    };


    return (
        <section className={className}>
            <div className="container mx-auto flex flex-wrap md:gap-4 md:grid md:grid-cols-3 items-start justify-between max-w-screen-lg">
                {/* Left Contact Info */}
                <div className="w-full mb-8 md:mb-0">
                    <h2 className="text-2xl font-bold mb-4 text-primary">Bize Ulaşın</h2>
                    <div className="bg-navBackground p-4 rounded-lg shadow-md">
                        <div className="mb-4 flex items-center">
                            <FaMapMarkerAlt className="text-green-600 mr-3" size={20} />
                            <p>
                                Üsküp, Makedonya
                            </p>
                        </div>
                        <div className="mb-4 flex items-center">
                            <FaPhoneAlt className="text-green-600 mr-3" size={20} />
                            <p>+389 72 577 451</p>
                        </div>
                        <div className="mb-4 flex items-center">
                            <FaEnvelope className="text-green-600 mr-3" size={20} />
                            <a href="mailto:info@elitmentordanismanlik.com">Bize E-posta Gönderin</a>
                        </div>
                        <div className="mb-4 flex items-center">
                            <FaWhatsapp className="text-green-600 mr-3" size={20} />
                            <a
                                href="https://wa.me/38972577451"
                                className="text-blue-500 hover:text-blue-700 transition-colors"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                WhatsApp ile İletişime Geçin
                            </a>
                        </div>
                    </div>
                </div>

                {/* Center Map */}
                <div className="w-full mb-8 md:mb-0">
                    <h2 className="text-2xl font-bold mb-4 text-primary">Yerimiz</h2>
                    <div className="w-full h-64 rounded-md overflow-hidden">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2554.652790543592!2d21.430172963796906!3d41.99609675736026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135415b475092443%3A0x2420e649a4b27994!2sMakedonya%20Meydan%C4%B1!5e0!3m2!1str!2str!4v1725194252032!5m2!1str!2str"
                            width="100%"
                            height="100%"
                            allowFullScreen=""
                            loading="lazy"
                            title="Map"
                        ></iframe>
                    </div>
                </div>

                {/* Right Contact Form */}
                <div className="w-full">
                    <h2 className="text-2xl font-bold mb-4 text-primary">İletişim Formu</h2>
                    <form onSubmit={handleSubmit} className="bg-navBackground p-4 rounded-lg shadow-md">
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                                Adınız
                            </label>
                            <input
                                className="w-full px-4 py-2 border-gray-200 rounded-md bg-navBackground focus:outline-none focus:ring-2 focus:ring-blue-500"
                                type="text"
                                id="name"
                                placeholder="Adınız"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                E-posta
                            </label>
                            <input
                                className="w-full px-4 py-2 border-gray-200 rounded-md bg-navBackground focus:outline-none focus:ring-2 focus:ring-blue-500"
                                type="email"
                                id="email"
                                placeholder="E-posta"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                                Mesajınız
                            </label>
                            <textarea
                                className="w-full px-4 py-2 border-gray-200 bg-navBackground rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                id="message"
                                rows="4"
                                placeholder="Mesajınızı yazın"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                            ></textarea>
                        </div>
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors"
                            type="submit"
                        >
                            Gönder
                        </button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default ContactUs;
